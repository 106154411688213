<template>
	<div class="w-100 d-flex trustpilot-mini-widget mb-4">
		<div class="my-auto w-100">
			<!-- TrustBox script -->
			<component
				is="script"
				src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
				async
			/>
			<!-- End TrustBox script -->
			<!-- TrustBox widget - Mini (Micro Combo) -->
			<div 
				class="trustpilot-widget" 
				style="padding-top: 20px"
				data-locale="en-GB" 
				data-template-id="53aa8807dec7e10d38f59f32" 
				data-businessunit-id="52951c010000640005776e2a" 
				data-style-height="120px" 
				data-style-width="100%" 
				data-theme="light"
			>
				<a 
					class="t-black mx-auto"
					href="https://uk.trustpilot.com/review/www.pactcoffee.com" 
					target="_blank" 
					rel="noopener"
				>Trustpilot</a> 
			</div> 
			<!-- End TrustBox widget -->
		</div>
	</div>
</template>

<script setup>
</script>
